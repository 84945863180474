import React from 'react';
import star_img from "./img/star.svg"

import m_house from "./img/svg/house.svg"

import m_onthebeach from "./img/svg/onthebeach.svg"
import m_worcation from "./img/svg/worcation.svg"
import m_chigasaki from "./img/svg/chigasaki.svg"
import m_atlier from "./img/svg/atlier.svg"
import m_farm from "./img/svg/farm.svg"
import m_farmtotable from "./img/svg/farmtotable.svg"
import m_farmshop from "./img/svg/farmshop.svg"

import m_pool from "./img/svg/pool.svg"
import m_sweeet from "./img/svg/sweeet.svg"
import m_bandb from "./img/svg/bandb.svg"
import m_rooftop from "./img/svg/rooftop.svg"
import m_moroiso from "./img/svg/moroiso.svg"
import m_farmpark from "./img/svg/farmpark_logo.png"

import d_house from "./img/detail/house.png"

import d_onthebeach from "./img/detail/onthebeach.png"
import d_worcation from "./img/detail/worcation.png"
import d_chigasaki from "./img/detail/chigasaki.png"
import d_atlier from "./img/detail/atlier.png"
import d_farm from "./img/detail/farm.png"
import d_farmtotable from "./img/detail/farmtotable.png"
import d_farmshop from "./img/detail/farmshop.png"

import d_pool from "./img/detail/pool.png"
import d_sweeet from "./img/detail/sweeet.png"
import d_bandb from "./img/detail/bandb.png"
import d_rooftop from "./img/detail/rooftop.png"
import d_moroiso from "./img/detail/moroiso.png"
import last_decate from "./img/lastdecade.png"
import d_farmpark from "./img/detail/farmpark.png"

import m_hayama from "./img/detail/m_hayama.png"
import m_jogasaki from "./img/detail/m_jogasaki.png"
import m_hayama_photo from "./img/detail/m_hayama_photo.jpg"
import m_jogasaki_photo from "./img/detail/m_jogasaki_photo.jpg"

import {differenceInSeconds} from "date-fns"

export class Header extends React.Component {


  constructor({ match }) {
    super();
    this.state = {
      years:null,
      days:null,
      mins:null,
      secs:null,
      detail:null
    };
  }

  componentDidMount() {
    const end_of_the_day = new Date("2025-08-17 00:00:00")
    const p = this
    setInterval(function(){
      const now = new Date()
      const sect = differenceInSeconds(end_of_the_day, now)
      console.log(sect)
      const years =  Math.floor(sect / (60 * 60 * 24 * 365))
      
      const days = Math.floor((sect - (years * 60 * 60 * 24 * 365)) / ( 60 * 60 * 24 ))
      const hours =Math.floor((sect - (years * 60 * 60 * 24 * 365) - (days * 60 * 60 * 24  )) / ( 60 * 60 ))
      const mins = Math.floor((sect - (years * 60 * 60 * 24 * 365) - (days * 60 * 60 * 24  ) - (hours * 60 * 60)) / 60)
      const secs = (sect - (years * 60 * 60 * 24 * 365) - (days * 60 * 60 * 24  ) - (hours * 60 * 60)  - (mins * 60 ) ) 
      p.setState({
        years:years,
        days:days,
        hours:hours,
        mins:mins,
        secs:secs
      })
    }, 1000)
  } 

  displayDetail(src){
    
    this.setState(
      {detail: 
        <div className="overlay">
        <div className="close" onClick={() => this.closeDetail(this) }><i className="fas fa-times"></i></div>
        <section className="detail_image">
          <img src={src} />
        </section>
       
        </div>
      }
    )
  }

  closeDetail(p){
    p.setState(
      {detail:null}
    )
  }


  render() {


    return (
      <div>
       
        <div className="last-decade">
          <div className="title-d"><img src={last_decate} /></div>
          <div className="end-to-time">
            <div className="big">{this.state.years}</div><div>year&nbsp;&nbsp;</div>
            <div className="big">{this.state.days}</div><div>days </div>
            <div className="big hour">{this.state.hours}</div><div>:</div>
            <div className="big">{this.state.mins}</div><div>:</div>
            <div className="big">{this.state.secs}</div>

          </div>
        </div>
      <header className="header">
        
        {this.state.detail}
        <div className="star is-pc"><a href="https://thehousehayama.com/thenewluxury/" target="_blank"><img src={star_img} /></a></div>
        <a href={this.props.category != "" ? "/photo/" + this.props.category : "/"}>
          <img className={"header_logo " + this.props.category} src={this.props.category != "" ?  "/img/family/" + this.props.image : "/img/logo_main.svg"} alt={this.props.category} />
        </a>
        <div className="header_sns is-pc">
          <div className="header_sns-item"><a href="https://www.instagram.com/the_house_hayama/" target="_blank"><i className="fab fa-instagram"></i></a></div>
          <div className="header_sns-item"><a href="https://www.instagram.com/thehousephotograph/" target="_blank"><i className="fab fa-instagram-square"></i></a></div>
          <div className="header_sns-item"><a href="https://www.pinterest.jp/yoshino7504/_saved/" target="_blank"><i className="fab fa-pinterest"></i></a></div>
          <div className="header_sns-item"><a href="https://note.com/thehousehayama/" target="_blank"><i className="far fa-sticky-note"></i></a></div>
        </div>
        
        {this.props.logos === "none" ? null :<nav className="header_nav">
          <ul  className="is-pc"><li onClick={() => this.displayDetail(d_house)}><img src={m_house} className="hlogo" width="151px" /></li></ul>
          <ul className=" is-pc">
             <li onClick={() => this.displayDetail(d_onthebeach)}><img src={m_onthebeach} className="hlogo" width="148px"/></li>
             <li onClick={() => this.displayDetail(d_worcation)}><img src={m_worcation} className="hlogo" width="131px"/></li>
             <li onClick={() => this.displayDetail(d_chigasaki)}><img src={m_chigasaki} className="hlogo" width="160px"/></li>
             <li onClick={() => this.displayDetail(d_atlier)}><img src={m_atlier} className="hlogo" width="144px"/></li>
             <li onClick={() => this.displayDetail(d_farm)}><img src={m_farm} className="hlogo" width="138px"/></li>
             <li onClick={() => this.displayDetail(d_farmtotable)}><img src={m_farmtotable} className="hlogo" width="148px"/></li>
             <li onClick={() => this.displayDetail(d_farmshop)}><img src={m_farmshop} className="hlogo" width="158px"/></li>
          </ul>
          <ul className=" is-pc">
              <li onClick={() => this.displayDetail(d_pool)}><img src={m_pool} className="hlogo" width="148px"/></li>
              <li onClick={() => this.displayDetail(d_sweeet)}><img src={m_sweeet} className="hlogo" width="148px"/></li>
              <li onClick={() => this.displayDetail(d_bandb)}><img src={m_bandb} className="hlogo" width="148px"/></li>
              <li onClick={() => this.displayDetail(d_rooftop)} ><img src={m_rooftop} className="hlogo" width="148px"/></li>
              <li onClick={() => this.displayDetail(d_moroiso)}><img src={m_moroiso} className="hlogo" width="148px"/></li>
          </ul>


          <ul className="margin-top-30  is-pc">
              <li onClick={() => this.displayDetail(d_farmpark)}><img src={m_farmpark} className="hlogo" width="220px"/></li>
          </ul>



          <ul className="margin-top-30 is-pc">
              <li onClick={() => this.displayDetail(m_hayama_photo)}><img src={m_hayama} className="hlogo" width="148px"/></li>
              <li onClick={() => this.displayDetail(m_jogasaki_photo)}><img src={m_jogasaki} className="hlogo" width="148px"/></li>
          
          </ul>
         
        </nav>
       }
       

      </header>
      </div>
    );
  }
}


 /****/