import React from 'react';
import 'whatwg-fetch'



import './index.css'
import { Link } from 'react-router-dom'
import { BlogListItem } from './list_item.js'
import {RightItem} from './right_item'
import {Header} from "./header"
import {DetailOver} from "./detail_over"
import Common from "./common"
import config from 'react-global-configuration';

export class BlogCategoryList extends React.Component {
 
  constructor({ match }) {
    super();
    const site = Common.getSite( match.params.catregory)
    this.counter = 0
    this.state = {
       entries: [],
       entries_sml: [],
       current_page :1,
       category:site.category,
       category_name:match.params.catregory,
       is_loading: false,
       site:site,
       overlay:null,
       fixitem:[],
       fixitem2:[],
       fixitem_bottom:[]
     };
  }

  componentDidMount() {
    this.getBlogFixData(this.state.category,this);
    this.getBlogFixData2(this.state.category,this);
    this.getBlogFixDataBottom(this.state.category,this);
   this.getBlogListData(this.state.category,this);
   this.getBlogSmlListData(this.state.current_page,this.state.category,this)

   const p = this
   window.addEventListener('scroll', function(){
     p.handleScroll()
   });

  }


  getBlogFixData(category,p) {

  
    fetch('https://thehouse.work/wp/wp-json/wp/v2/posts?_embed&per_page=1&categories=' + category + "&fix=1",   {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then(function(data) {
      p.setState({
         fixitem: data
      });
    }).catch(function(error) {    
      
      if(p.counter < 4)                         // catch
        window.setTimeout(p.getBlogFixData,5000,category,p)
    });
  }

  getBlogFixData2(category,p) {

  
    fetch('https://thehouse.work/wp/wp-json/wp/v2/posts?_embed&per_page=1&categories=' + category + "&fix2=1",   {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then(function(data) {

      p.setState({
         fixitem2: data
      });
    }).catch(function(error) {    
      
      if(p.counter < 4)                         // catch
        window.setTimeout(p.getBlogFixData2,5000,category,p)
    });
  }

  getBlogFixDataBottom(category,p) {

  
    fetch('https://thehouse.work/wp/wp-json/wp/v2/posts?_embed&per_page=1&categories=' + category + "&fix_bottom=1",   {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then(function(data) {

      p.setState({
         fixitem_bottom: data
      });
    }).catch(function(error) {    
      
      if(p.counter < 4)                         // catch
        window.setTimeout(p.getBlogFixDataBottom,5000,category,p)
    });
  }


  getBlogListData(category,p) {

  
    fetch('https://thehouse.work/wp/wp-json/wp/v2/posts?_embed&per_page=' + config.get("category_top_num") + '&categories=' + category + "&fix=0", {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then(function(data) {
      p.setState({
         entries: data
      });
    }).catch(function(error) {           
      p.counter ++
      if(p.counter < 4)                  // catch
        window.setTimeout(p.getBlogListData,5000,category,p)
    });
  }

  getBlogSmlListData(cp,category,p) {
    
    p.setState({is_loading:true})


    fetch('https://thehouse.work/wp/wp-json/wp/v2/posts?_embed&per_page=20&page='+ cp +'&categories=' + category + "&fix=0", {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then(function(data) {
     
      if(!data.data || data.data.status != 400){
        
        p.setState({
           entries_sml:p.state.entries_sml.concat(data),
           is_loading:false
        });
      }else{
        console.log("error")
      }

    }).catch(function(error) {    
      if(p.counter < 4)               // catch
        window.setTimeout(p.getBlogSmlListData,5000,cp,category,p)
    });
  }


//  getSideList()


  handleScroll() {

    const divTop = document.getElementById("root").offsetTop
    const divHeight = document.getElementById("root").offsetHeight
    var scrTop = document.documentElement.scrollTop
    const scrTopB = document.body.scrollTop
    const winHeight = window.innerHeight
    if(scrTop == 0){scrTop = scrTopB}
    if((divTop +  divHeight)  -  (scrTop + winHeight) < 200){

      if(!this.state.is_loading){
        this.state.current_page ++
        this.getBlogSmlListData(this.state.current_page,this.state.category,this)
      }
    }

  }

  linkTo(category_name, id){
    if(id)
      window.open("/photo/" + category_name + "/detail/" + id)
    else
    window.open("/photo/" + category_name )
  }


  render() {
    const fixid = this.state.fixitem.length === 0 ? 0 : this.state.fixitem[0].id
    const fixid2 = this.state.fixitem2.length === 0 ? 0 : this.state.fixitem2[0].id
    const fixid_bottom = this.state.fixitem_bottom.length === 0 ? 0 : this.state.fixitem_bottom[0].id
    
    var fix_list = this.state.fixitem.length === 0 ?  null
    
    : this.state.fixitem.map(item =>{
      
      const date_origin = new Date(item.date)
      const media_parent = item._embedded["wp:featuredmedia"]
      var media = null

      if(media_parent && media_parent[0].media_details && media_parent[0].media_details.sizes.large){
        media = media_parent[0].media_details.sizes.large.source_url
      }

      const category_parent = item._embedded["wp:term"]
      var category = null
      if(category_parent){
        category = category_parent[0][0].name
      }

      return (
        <BlogListItem key={item.id}
          media = {media}
          movie = {item.acf.movie}
          id = {item.id}
          tags = {item.tags}
          title = {item.title.rendered}
          date = {date_origin.getFullYear() + "-" + (date_origin.getMonth() + 1 ) + "-"+ date_origin.getDate()}
          category_name = {this.state.category_name}
          displayDetail = {(id) => this.setState({overlay:id})}
          youtube_id = {item.acf.youtube_id}
          linkTo = {this.linkTo}
        />

      )
      ;
    })

    var fix_list2 = this.state.fixitem2.length === 0 ?  null
    
    : this.state.fixitem2.map(item =>{
   
      const date_origin = new Date(item.date)
      const media_parent = item._embedded["wp:featuredmedia"]
      var media = null

      if(media_parent && media_parent[0].media_details && media_parent[0].media_details.sizes.large){
        media = media_parent[0].media_details.sizes.large.source_url
      }

      const category_parent = item._embedded["wp:term"]
      var category = null
      if(category_parent){
        category = category_parent[0][0].name
      }

      return (
        <BlogListItem key={item.id}
          media = {media}
          movie = {item.acf.movie}
          id = {item.id}
          tags = {item.tags}
          title = {item.title.rendered}
          date = {date_origin.getFullYear() + "-" + (date_origin.getMonth() + 1 ) + "-"+ date_origin.getDate()}
          category_name = {this.state.category_name}
          displayDetail = {(id) => this.setState({overlay:id})}
          youtube_id = {item.acf.youtube_id}
          linkTo = {this.linkTo}
        />

      )
      ;
    })


    var fix_list_bottom = this.state.fixitem_bottom.length === 0 ?  null
    
    : this.state.fixitem_bottom.map(item =>{
   
      const date_origin = new Date(item.date)
      const media_parent = item._embedded["wp:featuredmedia"]
      var media = null

      if(media_parent && media_parent[0].media_details && media_parent[0].media_details.sizes.large){
        media = media_parent[0].media_details.sizes.large.source_url
      }

      const category_parent = item._embedded["wp:term"]
      var category = null
      if(category_parent){
        category = category_parent[0][0].name
      }

      return (
        <BlogListItem key={item.id}
          media = {media}
          movie = {item.acf.movie}
          id = {item.id}
          tags = {item.tags}
          title = {item.title.rendered}
          date = {date_origin.getFullYear() + "-" + (date_origin.getMonth() + 1 ) + "-"+ date_origin.getDate()}
          category_name = {this.state.category_name}
          displayDetail = {(id) => this.setState({overlay:id})}
          youtube_id = {item.acf.youtube_id}
          linkTo = {this.linkTo}
        />

      )
      ;
    })

   

    var html_list = this.state.entries.length === 0 ?  <div className="loading"><i className="fas fa-circle-notch fa-spin"></i></div>

    
      : this.state.entries.map(item =>{
        if(item.id === fixid || item.id === fixid2 || item.id === fixid_bottom) return null
        
        const date_origin = new Date(item.date)
        const media_parent = item._embedded["wp:featuredmedia"]
        var media = null

        if(media_parent && media_parent[0].media_details && media_parent[0].media_details.sizes.large){
          media = media_parent[0].media_details.sizes.large.source_url
        }

        const category_parent = item._embedded["wp:term"]
        var category = null
        if(category_parent){
          category = category_parent[0][0].name
        }

        return (
          <BlogListItem key={item.id}
            media = {media}
            movie = {item.acf.movie}
            id = {item.id}
            tags = {item.tags}
            title = {item.title.rendered}
            date = {date_origin.getFullYear() + "-" + (date_origin.getMonth() + 1 ) + "-"+ date_origin.getDate()}
            category_name = {this.state.category_name}
            displayDetail = {(id) => this.setState({overlay:id})}
            linkTo = {this.linkTo}
          />

        )
        ;
      })

      

    const html_list_sml = this.state.entries_sml.map((item,step) =>{
      if(step < config.get("category_top_num")){
        return null
      }
      const date_origin = new Date(item.date)
      const media_parent = item._embedded["wp:featuredmedia"]
      var media = null
      if(media_parent && media_parent[0].media_details && media_parent[0].media_details.sizes.large){

        media = media_parent[0].media_details ? media_parent[0].media_details.sizes.medium_large.source_url : null
      }
      const movie = item.acf.movie

     


      return (
          <div className="photo-box" onClick={() => { item.tags.indexOf(47) >= 0 ? this.linkTo(this.state.category_name, item.id) : this.setState({overlay:item.id})}} >
          <div className='list-sml-item' >
          {media ? <img src={media} alt='{item.title.rendered}' /> : null}
          <div className='itembox'>
            <h2>{item.title.rendered}</h2>
          </div>
        </div>
        </div>
        );
      })

    const right_pannel = config.get("sites").map((item) =>{

      return (

        <RightItem item={item} />
        )
      })
    
    const overlay = this.state.overlay ?<DetailOver id={this.state.overlay}  closeDetail = {() => this.setState({overlay:null})} /> : null
   
    return (
      <div>
      <Header category={this.state.category_name} image = {this.state.site.image}/>
     

      {overlay}
      <main class="column">

        <section class="content">
          <div className="list">
            {fix_list}
            {fix_list2}
            {html_list}
            {fix_list_bottom}
          </div>

        </section>


        <section class="right-pannel">
        <div className="right-box" onClick={() => this.linkTo(this.state.category_name +"/tag/45")}>
          <div className="title-box">Movies <i className="fas fa-arrow-alt-right"></i></div> 
        </div>

          
          {right_pannel}
        </section>
      </main>

        <section class="content-bottom">
          <div className="list-sml">
            {html_list_sml}
          </div>
        </section>

      </div>
    );
  }


}

/*

ReactDOM.render(
  <BlogList />,
  document.getElementById('root')
);
*/
