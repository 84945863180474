import React from 'react'
import config from 'react-global-configuration';

const Common = {

  getSite(category){
    const list = config.get("sites")
    for(var i = 0; i < list.length; i++){
      const item = list[i]
      if(item.url === category ) return item
    }
    return null
  }

}

export default Common
