import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Link, Switch } from 'react-router-dom'

import { BlogList } from './list.js';
import { BlogCategoryList } from './list_category.js';
import { BlogStoryList } from './list_story.js';
import { BlogItem } from './detail.js';
import { BlogCategoryTagList } from './list_category_tag.js';

import config from 'react-global-configuration';
import configuration from './config.js';
config.set(configuration);

const App = () => (

      <Switch>

        <Route path='/photo/detail/:id' component={BlogItem} />
        <Route exact path='/photo/story/:catregory' component={BlogStoryList} />
        <Route exact path='/photo/:catregory' component={BlogCategoryList} />
        <Route path='/photo/:catregory/detail/:id' component={BlogItem} />
        <Route exact path='/photo/:catregory/tag/:tag' component={BlogCategoryTagList} />
        <Route exact path='/' component={BlogList} />


        <Route component={BlogList}/>

      </Switch>

)
ReactDOM.render(
  (<BrowserRouter><App /></BrowserRouter>),
  document.getElementById('root')
)
