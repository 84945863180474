const config = {

  sites:[
    {image:"gdesign.svg", url:"thehousedesign", category:49},
    {image:"photo.svg", url:"thehousephotograph", category:1},
    {image:"girls.png", url:"girls", category:35},
    {image:"farm.png", url:"farm", category:33},
    {image:"shop.png", url:"shop", category:19},
    {image:"logo_main.svg", url:"thehousephoto", category:27},
    {image:"wedding.png", url:"wedding", category:31},
    {image:"live.png", url:"live", category:32},
    {image:"food.png", url:"thehousefood", category:30},
    {image:"furnituure.png", url:"r134thshop", category:23},
    {image:"club.png", url:"club", category:24},
    {image:"hayamacation.png", url:"hayamacation", category:25},
    {image:"e-hayamacation.png", url:"ehayamacation", category:26},
    {image:"art.svg", url:"thehouseart", category:29},
    {image:"publication.svg", url:"publication", category:28},
    {image:"ad.svg", url:"advertising", category:42},
    {image:"mysweethoney.svg", url:"mysweethoney", category:43},
    {image:"mediacoverage.svg", url:"mediacoverage", category:48}

  ],
  category_top_num:6
}

export default config;
