import React from 'react';
import 'whatwg-fetch'
import './index.css'
import { Link } from 'react-router-dom'

export class BlogListItem extends React.Component {
  
  render() {
   if(this.props.youtube_id){
     const src = "https://www.youtube.com/embed/" + this.props.youtube_id + "?autoplay=1&mute=1&playsinline=1&loop=1&playlist=" + this.props.youtube_id
    return  (
      <div className={'list-item youtube'}>
      <iframe  src={src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     </div>
    )
  
   }else{
    return (

      <div className={'list-item '} >
      {this.props.movie ? <video  controls poster={this.props.media} muted={true}><source src={this.props.movie}></source></video>:
      this.props.media ? this.props.tags.indexOf(47) >= 0 ? <img src={this.props.media} alt={this.props.title} onClick={() => this.props.linkTo(this.props.category_name, this.props.id)}/> : <img src={this.props.media} alt={this.props.title} onClick={() => this.props.displayDetail(this.props.id)}/> : null 
      }
       
          <div className='itembox' onClick={() => this.props.displayDetail(this.props.id)}>
            {this.props.text_display === "none" ? null : <div className="main-list-metainfo">{this.props.date} | {this.props.category_name}</div> }
            {this.props.text_display === "none" ? null :<h2>{this.props.title}</h2>}
          </div>
        
      </div>

     );
   }
   
  }
}
