import React from 'react';
import 'whatwg-fetch'



import './index.css'
import { Link } from 'react-router-dom'
import { BlogListItem } from './list_item.js'
import {RightItem} from './right_item'
import {Header} from "./header"
import {DetailOver} from "./detail_over"
import Common from "./common"
import config from 'react-global-configuration';

export class BlogCategoryTagList extends React.Component {

  constructor({ match }) {
    super();

    const site = Common.getSite( match.params.catregory)
    
    this.state = {
       entries: [],
       entries_sml: [],
       current_page :1,
       category:site.category,
       category_name:match.params.catregory,
       tag: match.params.tag,
       is_loading: false,
       site:site,
       overlay:null,
       fixitem:[]
     };
  }

  componentDidMount() {
   this.getBlogSmlListData(this.state.current_page,this.state.category,this.state.tag, this)

   const p = this
   window.addEventListener('scroll', function(){
     p.handleScroll()
   });

  }


  getBlogSmlListData(cp,category,tag,p) {

    p.setState({is_loading:true})


    fetch('https://thehouse.work/wp/wp-json/wp/v2/posts?_embed&per_page=20&page='+ cp  + "&tags=" + tag + "&fix=0", {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then(function(data) {
     
      if(!data.data || data.data.status != 400){
        
        p.setState({
           entries_sml:p.state.entries_sml.concat(data),
           is_loading:false
        });
      }else{
        console.log("error")
      }

    }).catch(function(error) {    
                     // catch
      window.setTimeout(p.getBlogSmlListData,5000,cp,category,p)
    });
  }


//  getSideList()


  handleScroll() {

    const divTop = document.getElementById("root").offsetTop
    const divHeight = document.getElementById("root").offsetHeight
    var scrTop = document.documentElement.scrollTop
    const scrTopB = document.body.scrollTop
    const winHeight = window.innerHeight
    if(scrTop == 0){scrTop = scrTopB}
    if((divTop +  divHeight)  -  (scrTop + winHeight) < 200){

      if(!this.state.is_loading){
        this.state.current_page ++
        this.getBlogSmlListData(this.state.current_page,this.state.category,this.state.tag,this)
      }
    }

  }


  render() {
    

    const html_list_sml = this.state.entries_sml.map((item,step) =>{
    
      const date_origin = new Date(item.date)
      const media_parent = item._embedded["wp:featuredmedia"]
      var media = null
      if(media_parent && media_parent[0].media_details && media_parent[0].media_details.sizes.large){

        media = media_parent[0].media_details ? media_parent[0].media_details.sizes.medium_large.source_url : null
      }
      const movie = item.acf.movie

    
      return (
          <div className="photo-box" onClick={() => this.setState({overlay:item.id})}>
          <div className='list-sml-item' >
          {media ? <img src={media} alt='{item.title.rendered}' /> : null}
          <div className='itembox'>
            <h2>{item.title.rendered}</h2>
          </div>
        </div>
        </div>
        );
      })

    const right_pannel = config.get("sites").map((item) =>{

      return (

        <RightItem item={item} />
        )
      })
    
    const overlay = this.state.overlay ?<DetailOver id={this.state.overlay}  closeDetail = {() => this.setState({overlay:null})} /> : null

    return (
      <div>
      <Header category={this.state.category_name} image = {this.state.site.image}/>
      

      {overlay}
      <main class="column">

  
      </main>

        <section class="content-bottom">
          <div className="list-sml">
            {html_list_sml}
          </div>
        </section>

      </div>
    );
  }


}

/*

ReactDOM.render(
  <BlogList />,
  document.getElementById('root')
);
*/
