import React from 'react';
import 'whatwg-fetch'
import './index.css'
import { Link } from 'react-router-dom'

export class RightItem extends React.Component {

  
  constructor({ match }) {
    super();
    this.counter = 0
    this.state = {
    
     items:[]
    };
  }

  componentDidMount() {
   this.getBlogListData(this.props.item.category, this);
  }

  getBlogListData(category, p) {

    const url = 'https://thehouse.work/wp/wp-json/wp/v2/posts?_embed&per_page=1&categories=' + category
    fetch( url, {
      method: 'GET'
    }).then(function(response) {
    
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else{
        return {"error":402}
      }

    }).then(function(data) {
      if(data && !data.error){
        p.setState({
          items: data
        });
      }else{
        window.setTimeout(p.getBlogListData,5000,category,p)
      }
    }).catch(function(error) {       
      p.counter ++       
      console.log(p.counter)       
      if(p.counter < 4)     // catch
        window.setTimeout(p.getBlogListData,5000,category,p)
    });
  }

  linkTo(url){
    window.location.href = ("/photo/" + url)
  }

  render() {

    const _html = this.state.items.map((item) => {
      const date_origin = new Date(item.date)

      const media_parent = item._embedded["wp:featuredmedia"]
      var media = null
     
      if(media_parent && media_parent[0].media_details){
        media = media_parent[0].media_details.sizes.medium_large.source_url
      }
      return (<a href={this.props.url} key={item.id} className="photo-box">
        <div className='list-sml-item right' >
          {media ? <img src={media} alt={item.title.rendered} /> : null }  
          <div className='itembox'>
            <div className="main-list-metainfo">{date_origin.getFullYear() + "-" + (date_origin.getMonth() + 1 ) + "-"+ date_origin.getDate()} </div>
            <h2>{item.title.rendered}</h2>
          </div>
        </div>
      </a>)
    })

    return (

      <div className="right-box" onClick={() => this.linkTo(this.props.item.url)}>

        <div className="right-logo">
          <img src= {`${process.env.PUBLIC_URL}/img/family/${this.props.item.image}`} className={this.props.item.url} /></div>
        {_html}
      </div>

    );
  }
}
