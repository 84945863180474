import React from 'react';
import 'whatwg-fetch'



import './index.css'
import { Link } from 'react-router-dom'
import { BlogListItem } from './list_item.js'
import {RightItem} from './right_item'
import {Header} from "./header"
import {DetailOver} from "./detail_over"
import Common from "./common"
import config from 'react-global-configuration';

export class BlogStoryList extends React.Component {

  constructor({ match }) {
    super();
    const site = Common.getSite( match.params.catregory)
   
    this.state = {
       entries: [],
       entries_sml: [],
       current_page :1,
       category:site.category,
       category_name:match.params.catregory,
       is_loading: false,
       site:site,
       overlay:null,
       fixitem:[]
     };
  }

  componentDidMount() {
    this.getBlogFixData(this.state.category,this);
    this.getBlogListData(this.state.category,this);
  }


  getBlogFixData(category,p) {

  
    fetch('https://thehouse.work/wp/wp-json/wp/v2/posts?_embed&per_page=1&categories=' + category + "&fix=1",   {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then(function(data) {
      p.setState({
         fixitem: data
      });
    }).catch(function(error) {                    // catch
      window.setTimeout(p.getBlogFixData,5000,category,p)
    });
  }


  getBlogListData(category,p) {

  
    fetch('https://thehouse.work/wp/wp-json/wp/v2/posts?_embed&per_page=50&categories=' + category + "&fix=0", {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then(function(data) {
      p.setState({
         entries: data
      });
    }).catch(function(error) {                             // catch
      window.setTimeout(p.getBlogListData,5000,category,p)
    });
  }


//  getSideList()

  linkTo(category_name, id){
    if(id)
      window.open("/photo/" + category_name + "/detail/" + id)
    else
    window.open("/photo/" + category_name )
  }


  render() {
    const fixid = this.state.fixitem.length === 0 ? 0 : this.state.fixitem[0].id
    var fix_list = this.state.fixitem.length === 0 ?  null
    
    : this.state.fixitem.map(item =>{
      
      const date_origin = new Date(item.date)
      const media_parent = item._embedded["wp:featuredmedia"]
      var media = null

      if(media_parent && media_parent[0].media_details && media_parent[0].media_details.sizes.large){
        media = media_parent[0].media_details.sizes.large.source_url
      }

      const category_parent = item._embedded["wp:term"]
      var category = null
      if(category_parent){
        category = category_parent[0][0].name
      }

      return (
        <BlogListItem key={item.id}
          media = {media}
          movie = {item.acf.movie}
          id = {item.id}
          tags = {item.tags}
          title = {item.title.rendered}
          date = {date_origin.getFullYear() + "-" + (date_origin.getMonth() + 1 ) + "-"+ date_origin.getDate()}
          category_name = {this.state.category_name}
          displayDetail = {(id) => this.setState({overlay:id})}
          youtube_id = {item.acf.youtube_id}
          linkTo = {this.linkTo}
          text_display = "none"
        />

      )
      ;
    })


    var html_list = this.state.entries.length === 0 ?  <div className="loading"><i className="fas fa-circle-notch fa-spin"></i></div>

    
      : this.state.entries.map(item =>{
        if(item.id === fixid) return null
        
        const date_origin = new Date(item.date)
        const media_parent = item._embedded["wp:featuredmedia"]
        var media = null

        if(media_parent && media_parent[0].media_details && media_parent[0].media_details.sizes.large){
          media = media_parent[0].media_details.sizes.large.source_url
        }

        const category_parent = item._embedded["wp:term"]
        var category = null
        if(category_parent){
          category = category_parent[0][0].name
        }

        return (
          <BlogListItem key={item.id}
            media = {media}
            movie = {item.acf.movie}
            id = {item.id}
            tags = {item.tags}
            title = {item.title.rendered}
            date = {date_origin.getFullYear() + "-" + (date_origin.getMonth() + 1 ) + "-"+ date_origin.getDate()}
            category_name = {this.state.category_name}
            displayDetail = {(id) => this.setState({overlay:id})}
            linkTo = {this.linkTo}
            text_display = "none"
          />

        )
        ;
      })

      

    
    const overlay = this.state.overlay ?<DetailOver id={this.state.overlay}  closeDetail = {() => this.setState({overlay:null})} /> : null

    return (
      <div>
      <Header category={this.state.category_name} image = {this.state.site.image} logos="none" />
     

      {overlay}
      <main class="column">

        <section class="content">
          <div className="list">
            {fix_list}
            {html_list}
          </div>

        </section>

      </main>


      </div>
    );
  }


}

/*

ReactDOM.render(
  <BlogList />,
  document.getElementById('root')
);
*/
