import React from 'react';
import 'whatwg-fetch'
import './blog.css'
import {RightItem} from './right_item'
import config from 'react-global-configuration';
import {Header} from "./header"
import Common from "./common"

export class DetailOver extends React.Component {

  constructor() {
    super();
    this.state = {
       entry: null,
     };
  }

  componentDidMount() {
   this.getBlogDetailData();

  }

  getBlogDetailData() {
    const p = this
    fetch('https://thehouse.work/wp/wp-json/wp/v2/posts/' + this.props.id + '?_embed', {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then(function(data) {
      p.setState({
         entry: data
      });
    });
  }

  closeDetail(p){
    p.props.closeDetail()
  }


  render() {
      if(!this.state.entry){
        return(
          <div className="overlay">
          <div className="close" onClick={() => this.closeDetail(this) }><i className="fas fa-times"></i></div>
          <section class="detail loading">
            <i className="fas fa-circle-notch fa-spin"></i>
          </section>
        </div>
        )
      }

      

      const item = this.state.entry
      const date_origin = new Date(item.date)
      const media_parent = item._embedded["wp:featuredmedia"]


      const category_parent = item._embedded["wp:term"]
      var category = null
      if(category_parent){
        category = category_parent[0][0].name
      }

      var movie = null
      if(item.acf.youtube_id){
        movie = "https://www.youtube.com/embed/" + item.acf.youtube_id + "?autoplay=1&mute=1&playsinline=1&loop=1&playlist=" + item.acf.youtube_id
      }
    
    return (
      <div className="overlay">
          <div className="close" onClick={() => this.closeDetail(this) }><i className="fas fa-times"></i></div>
          <section class="detail ">
            <div className='item-detail' key={item.id}>
              <h2>{item.title.rendered}</h2>
              <div className="main-detail-category">{category}</div>
              <div className="main-detail-date">{date_origin.getFullYear() + "-" + (date_origin.getMonth() + 1 ) + "-"+ date_origin.getDate()}</div>
              {
                  movie  ?
                  <div className={'youtube movie_detail'}>
                   <iframe  src={movie} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>

                 : <article dangerouslySetInnerHTML={{__html: item.content.rendered}}></article>
              }
              
              {
                item.acf.shopurl ? <div className="shopurl">商品の購入はこちらから: <a href={item.acf.shopurl} target="_blank">{item.acf.shopurl}</a></div> : null
              }
            </div>
          </section>
         
      </div>


    );
  }


}

/*
ReactDOM.render(
  <BlogItem />,
  document.getElementById('root')
);
*/
