import React from 'react';
import 'whatwg-fetch'
import './blog.css'
import {RightItem} from './right_item'
import config from 'react-global-configuration';
import {Header} from "./header"
import Common from "./common"

export class BlogItem extends React.Component {

  constructor({ match }) {
    super();
    const site = Common.getSite( match.params.catregory)
    this.state = {
       entry: null,
       id:match.params.id,
       category_name:match.params.catregory,
       site:site
     };
  }

  componentDidMount() {
   this.getBlogDetailData();

  }

  getBlogDetailData() {
    const p = this
    fetch('https://thehouse.work/wp/wp-json/wp/v2/posts/' + this.state.id + '?_embed', {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then(function(data) {
      p.setState({
         entry: data
      });
    });
  }


  render() {
      if(!this.state.entry){
        return null
      }

      const item = this.state.entry
      const date_origin = new Date(item.date)
      const media_parent = item._embedded["wp:featuredmedia"]


      const category_parent = item._embedded["wp:term"]
      var category = null
      if(category_parent){
        category = category_parent[0][0].name
      }

      const right_pannel = config.get("sites").map((item) =>{
        return (
          <RightItem item={item} />
          )
        })

    return (
      <div>
        <Header category={this.state.category_name} image = {this.state.site.image}/>
        <section class="title"><img src="/img/title.png" /></section>
        <main class="column">

          <section class="detail">
            <div className='item-detail' key={item.id}>
              <h2>{item.title.rendered}</h2>
              <div className="main-detail-category">{category}</div>
              <div className="main-detail-date">{date_origin.getFullYear() + "-" + (date_origin.getMonth() + 1 ) + "-"+ date_origin.getDate()}</div>
              {
                  item.acf.movie  ?
                 <video  controls poster={this.props.media} muted={true}><source src={this.props.movie}></source></video>
                 : <article dangerouslySetInnerHTML={{__html: item.content.rendered}}></article>
              }
              
              {
                item.acf.shopurl ? <div className="shopurl">商品の購入はこちらから: <a href={item.acf.shopurl} target="_blank">{item.acf.shopurl}</a></div> : null
              }
            </div>
          </section>
          <section class="right-pannel">
            {right_pannel}
          </section>
        </main>
      </div>


    );
  }


}

/*
ReactDOM.render(
  <BlogItem />,
  document.getElementById('root')
);
*/
